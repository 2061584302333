import { H1 } from "components/typography"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import Sidebar from "./Sidebar"
import SettingItem from "./SettingItem"
import { ISetting } from "models/ISetting"
import { FaRegBell } from "react-icons/fa"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { FiDatabase } from "react-icons/fi"
import { breakpoints } from "utils"
import { UserType } from "api/types"
import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import { apiFetchUser, apiPutProfileData } from "../../api/queries"
import { PutUserProfileInput } from "../../api/graphql/API"

const Container = styled.div`
  margin-top: 38px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Content = styled(Row)`
  margin-top: 36px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Settings = styled(Col)``
const BellIcon = styled(FaRegBell)`
  height: 28px;
  width: 28px;
  fill: #ec4d7b;
`
const DatabaseIcon = styled(FiDatabase)`
  height: 24px;
  width: 24px;
  stroke: #ec4d7b;
`
const InfoIcon = styled(AiOutlineInfoCircle)`
  height: 24px;
  width: 24px;
  fill: #ec4d7b;
`

const createSettings = (user: any) => {
  let { additional_data } = user
  additional_data = JSON.parse(additional_data || "{}")
  return [
    {
      id: 1,
      icon: InfoIcon,
      title: "Application sign up consent",
      description: "Consent to AskEM Terms of use & privacy policy.",
      defaultValue: user.consent,
    },
    {
      id: 2,
      icon: InfoIcon,
      title: "Further research",
      description: "Consent to be included in further research.",
      defaultValue: additional_data.consent_1 || false,
    },
    {
      id: 5,
      icon: InfoIcon,
      title: "Future contact",
      description: "Consent to be contacted in future.",
      defaultValue: additional_data.consent_2 || false,
    },
  ] as ISetting[]
}

interface Props {
  loggedInUser: UserType
  updateUser: Function
}

const ProfilePage: React.FC<Props> = ({ loggedInUser, updateUser }) => {
  const [user, setUser] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const [settings, setSettings] = useState<ISetting[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!loggedInUser) return
    Amplify.configure(AWS_CONFIG)
    apiFetchUser(loggedInUser.id || "")
      .then((data: any) => {
        if (!data) throw new Error("There is no data")
        setSettings(createSettings(data))
        setUser(data)
      })
      .catch(err => {
        setError("Something went wrong while fetching user data")
        console.error({ err })
      })
  }, [loggedInUser])

  const syncSettings = (settings: ISetting[], user: any, before = () => {}) => {
    before()
    return new Promise((resolve, reject) => {
      apiPutProfileData({
        fullName: user.fullName,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        mobileNumber: user.mobileNumber,
        consent: settings.filter(item => item.id === 1)[0].defaultValue,
        additional_data: JSON.stringify({
          consent_1: settings.filter(item => item.id === 2)[0].defaultValue,
          consent_2: settings.filter(item => item.id === 5)[0].defaultValue,
        }),
      } as PutUserProfileInput)
        .then(data => resolve(data))
        .catch(err => reject(err))
    })
  }

  const onChange = (id: any, value: boolean) => {
    if (loading) return
    const _settings = settings.map(item => {
      if (item.id === id) return { ...item, defaultValue: value }
      return item
    })
    setSettings(_settings)

    syncSettings(_settings, loggedInUser, () => setLoading(true))
      .then(data => {
        setLoading(false)
        setUser(data)
      })
      .catch(err => {
        setLoading(false)
        setError("Something went wrong while updating the settings")
        console.error({ err })
      })
  }

  return (
    <Container>
      <H1>My Profile</H1>
      <Content>
        <Sidebar
          xs="12"
          sm="4"
          md="3"
          loggedInUser={loggedInUser}
          updateUser={updateUser}
          userProfile={user}
        />
        <Settings xs="12" sm="8" md="9">
          {settings.map(item => (
            <SettingItem key={item.id} {...item} onChange={onChange} />
          ))}
        </Settings>
      </Content>
    </Container>
  )
}

export default ProfilePage
