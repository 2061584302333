import React from "react"
import styled from "styled-components"
import { FormCheck } from "react-bootstrap"
import { ISetting } from "models/ISetting"

const Container = styled.div`
  background: #f6f6f6;
  border-radius: 7px;
  display: flex;
  padding: 16px;
  padding-right: 32px;
  margin-bottom: 10px;
  user-select: none;
`
const Content = styled.div`
  margin-left: 16px;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 5px;
`
const Description = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
`
const Check = styled(FormCheck)`
  margin-left: auto;
`

const SettingItem: React.FC<ISetting> = props => {
  const Icon = props.icon
  return (
    <Container>
      <Icon />
      <Content>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </Content>
      <Check
        type="switch"
        id={`settings-switch-${props.id}`}
        checked={props.defaultValue}
        onChange={() => {
          if (props.title === "Application sign up consent" || props.id == 3)
            return
          if (!props.onChange) return
          props.onChange(props.id, !props.defaultValue)
        }}
      />
    </Container>
  )
}

export default SettingItem
