import { Button } from "components/buttons"
import { FormControl, Label } from "components/Form"
import MobileModal from "components/MobileModal"
import React, { useState, useEffect } from "react"
import { Col, Form } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import Modal from "../dashboardPage/Modal"
import ModalContent from "../dashboardPage/Modal/ModalContent"
import ErrorText from "../ErrorText"
import format from "date-fns/format"
import { UserType } from "api/types"
import PleaseWaitButton from "../form/PleaseWaitButton"
import PhoneNumberInput from "components/form/PhoneNumberInput"

const Container = styled.div``
const CustomModal = styled(Modal)`
  &.modal.fade:not(.in).bottom .modal-dialog {
    transform: translate3d(0, 25%, 0) !important;
  }
  bottom: 0;
`
interface Props {
  show: boolean
  onClose: Function
  onSubmit: Function
  error: iProfileFormError
  userProps: UserType | null
  loading: boolean
}
enum ErrorType {
  fullName,
  mobileNumber,
  dateOfBirth,
  email,
}
interface FormParams {
  fullName: string
  mobileNumber: string
  dateOfBirth: string
  email: string
}
interface iProfileFormError {
  text: string | null
  type: ErrorType | null
}

const ProfileModal: React.FC<Props> = props => {
  const [form, setForm] = useState({
    fullName: "",
    mobileNumber: "",
    dateOfBirth: "",
    email: "",
  } as FormParams)

  const onChange = (field: string, value: string) => {
    setForm(
      Object.assign({}, form, {
        [field]: value,
      })
    )
  }

  useEffect(() => {
    const { userProps } = props
    if (userProps) {
      form.fullName = userProps?.fullName || ""
      form.mobileNumber = userProps.mobileNumber || ""
      form.dateOfBirth = userProps.dateOfBirth
        ? format(new Date(userProps.dateOfBirth), "yyyy-MM-dd")
        : ""
      form.email = userProps?.email || ""
    }
  }, [props.userProps])

  const formatDate = (dateStr: string) => {
    const len = dateStr.length
    let formatted = dateStr
    if (len === 4) {
      formatted = dateStr.substring(0, 4) + "-"
    } else if (len === 7) {
      formatted = dateStr.substring(0, 7) + "-"
    } else if (len > 7) {
      formatted = dateStr.substring(0, 10)
    }
    return formatted
  }

  const footer = !props.loading ? (
    <Button onClick={() => props.onSubmit(form)}>Update</Button>
  ) : (
    <PleaseWaitButton />
  )

  const getContent = (error: iProfileFormError) => {
    return (
      <ModalContent
        title="Update Profile"
        subtitle=""
        footer={footer}
        onClose={props.onClose}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <ErrorText text={error.text} />
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Full name</Label>
            <FormControl
              onChange={(e: any) => onChange("fullName", e.target.value)}
              placeholder="Enter your full name"
              value={form.fullName}
              maxLength={256}
              className={
                error.type === ErrorType.fullName
                  ? "border-red-600 hover:border-red-700"
                  : ""
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Email</Label>
            <FormControl
              onChange={(e: any) => onChange("email", e.target.value)}
              placeholder="Enter your email"
              value={form.email}
              className={
                error.type === ErrorType.email
                  ? "border-red-600 hover:border-red-700"
                  : ""
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Mobile number</Label>
            <PhoneNumberInput
              label=""
              onChange={e => {
                onChange("mobileNumber", e)
              }}
              placeholder={"+61 (41) 2345 678"}
              value={form.mobileNumber}
              error={error.type === ErrorType.mobileNumber}
              disable={false}
              initCountry={"au"}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Date of birth</Label>
            <FormControl
              onChange={(e: any) =>
                onChange("dateOfBirth", formatDate(e.target.value))
              }
              placeholder="Enter your date of birth YYYY-MM-DD"
              value={form.dateOfBirth}
              type="date"
              className={
                error.type === ErrorType.dateOfBirth
                  ? "border-red-600 hover:border-red-700"
                  : ""
              }
            />
          </Form.Group>
        </Form.Row>
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return (
      <MobileModal show={props.show}>{getContent(props.error)}</MobileModal>
    )
  }

  return (
    <Container>
      <CustomModal show={props.show} onClose={props.onClose}>
        {getContent(props.error)}
      </CustomModal>
    </Container>
  )
}

export default ProfileModal
