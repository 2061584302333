import { navigate } from "gatsby"
import { H7 } from "components/typography"
import React, { useState, useEffect } from "react"
import { Col } from "react-bootstrap"
import styled, { css } from "styled-components"
import { BiUser } from "react-icons/bi"
import { FiMail } from "react-icons/fi"
import { FaRegCalendar } from "react-icons/fa"
import { BiPhone } from "react-icons/bi"
import { Button } from "components/buttons"
import { breakpoints } from "utils"
import differenceInYears from "date-fns/differenceInYears"
import isValid from "date-fns/isValid"
import { apiPutProfileData } from "api/queries"
import ProfileModal from "./profileModal"
import Avatar from "react-avatar"
import theme from "components/layout/theme"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import { UserType } from "api/types"
import { validateEmail } from "./utils"
import { toast } from "react-toastify"
import toastError from "utils/toastError"
import { isBrowser } from "utils"

const Container = styled(Col)`
  padding: 36px 18px 24px;
  background: #f6f6f6;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const ProfilePhoto = styled(Avatar)`
  // border: 2px solid #ec4d7b;
  border-radius: 50%;
  width: 86px;
  height: 86px;
  margin-bottom: 30px;
  align-self: center;
`
const Info = styled.div`
  margin-bottom: 12px;
`
const Label = styled.div`
  display: flex;
  justify-content: space-between;
`
const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const iconStyles = css`
  fill: #ec4d7b;
  height: 18px;
  width: 18px;
`
const UserIcon = styled(BiUser)`
  ${iconStyles}
`
const MailIcon = styled(FiMail)`
  ${iconStyles};
  stroke: #ec4d7b;
  fill: #fff;
`
const CalendarIcon = styled(FaRegCalendar)`
  ${iconStyles}
`
const PhoneIcon = styled(BiPhone)`
  ${iconStyles}
`
const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & button {
    margin-bottom: 12px;
  }
`
const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

interface Props {
  md?: string
  sm?: string
  xs?: string
  lg?: string
  className?: string
  loggedInUser: UserType | null
  updateUser: Function
  userProfile: any
}

enum ErrorType {
  fullName,
  mobileNumber,
  dateOfBirth,
  email,
}
interface iProfileFormError {
  text: string | null
  type: ErrorType | null
}

interface FormParams {
  fullName: string
  mobileNumber: string
  dateOfBirth: string
  email: string
}

const errorFieldMapping = {
  fullName: "Full name",
  mobileNumber: "Mobile number",
  dateOfBirth: "Date of birth",
  email: "Email",
} as FormParams

const Sidebar: React.FC<Props> = ({
  className,
  xs,
  sm,
  md,
  lg,
  updateUser,
  loggedInUser,
  userProfile,
}) => {
  const [formIsVisible, setFormIsVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<iProfileFormError>({
    text: null,
    type: null,
  })
  const [userProps, setUserProps] = useState<UserType | null>(null)

  useEffect(() => {
    setUserProps(loggedInUser)
  }, [loggedInUser])

  useEffect(() => {
    if (isBrowser()) {
      const search = window.location.search
      if (search) {
        const [key, value] = search.slice(1).split("=")
        if (key === "popup" && value === "true") {
          setFormIsVisible(true)
        }
      }
    }
  }, [])

  const onFormClose = () => {
    setError({
      text: null,
      type: null,
    })
    setFormIsVisible(false)
  }

  const validateInput = ({
    fullName,
    mobileNumber,
    dateOfBirth,
    email,
  }: FormParams) => {
    if (!fullName)
      return {
        text: "Please, enter a full name",
        type: ErrorType.fullName,
      }
    if (!email)
      return { text: "Please, enter an email address", type: ErrorType.email }
    if (!validateEmail(email))
      return { text: "Email address is not valid", type: ErrorType.email }
    if (!mobileNumber || mobileNumber === "+")
      return {
        text: "Please, enter a mobile number",
        type: ErrorType.mobileNumber,
      }
    if (!isValid(new Date(dateOfBirth)))
      return {
        text: "Date of birth is not a valid date!",
        type: ErrorType.dateOfBirth,
      }
    const yrs = differenceInYears(Date.now(), new Date(dateOfBirth))
    if (yrs > 100 || yrs === 0) {
      return {
        text: "Please enter a real date of birth!",
        type: ErrorType.dateOfBirth,
      }
    }
    return null
  }
  const onFormSubmit = (form: FormParams) => {
    if (loading) return
    setError({
      text: null,
      type: null,
    })
    const err = validateInput(form)
    if (err) {
      setError(err)
      setLoading(false)
      return
    }
    setLoading(true)
    apiPutProfileData({
      ...form,
      consent: userProfile.consent,
      additional_data: userProfile.additional_data,
    })
      .then(data => {
        setLoading(false)
        setFormIsVisible(false)
        updateUser(data)
        setUserProps({ ...data, email: form.email })
        if (isBrowser()) {
          window.history.pushState({}, "", "/profile")
        }
      })
      .catch(e => {
        console.error(e)
        const errorField = e.errors[0].message.split(" ")[1].replace(/\'/g, "")
        const errorMsg = `${
          errorFieldMapping[errorField as keyof FormParams]
        } has an invalid value.`
        if (ErrorType[errorField] !== undefined) {
          setError({
            text: errorMsg,
            type: ErrorType[errorField as keyof FormParams],
          })
        } else {
          toastError(toast, errorField)
        }
        setLoading(false)
      })
  }

  let age = ""
  let fullName
  if (userProps) {
    fullName = userProps.fullName
    const { dateOfBirth } = userProps
    if (dateOfBirth) {
      age = differenceInYears(Date.now(), new Date(dateOfBirth)).toString()
    }
  }

  return (
    <Container className={className} xs={xs} sm={sm} md={md} lg={lg}>
      <ProfilePhoto name={fullName} color={theme.colors.primary} />
      <Info>
        <Label>
          <H7>NAME</H7>
          <UserIcon />
        </Label>
        <Value>{userProps?.fullName && userProps?.fullName}</Value>
      </Info>
      <Info>
        <Label>
          <H7>EMAIL</H7>
          <MailIcon />
        </Label>
        <Value>{userProps?.email && userProps?.email}</Value>
      </Info>
      <Info>
        <Label>
          <H7>AGE</H7>
          <CalendarIcon />
        </Label>
        <Value>{age} yrs</Value>
      </Info>
      <Info>
        <Label>
          <H7>MOBILE NO</H7>
          <PhoneIcon />
        </Label>
        <Value>{userProps?.mobileNumber && userProps?.mobileNumber}</Value>
      </Info>
      <ProfileModal
        error={error}
        show={formIsVisible}
        onClose={onFormClose}
        onSubmit={onFormSubmit}
        loading={loading}
        userProps={loggedInUser}
      />
      <Footer>
        <StyledButton onClick={() => setFormIsVisible(true)}>
          Update Profile
        </StyledButton>
        <StyledButton outline onClick={() => navigate("/changepwd")}>
          Change Password
        </StyledButton>
      </Footer>
    </Container>
  )
}

export default Sidebar
